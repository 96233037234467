<template>
  <div class="wrapper">
    <div class="left-menu">
      <van-tabs v-model:active="activeKey" @change="tabChange">
        <van-tab v-for="item in tabList" :key="item.dickey" :title="item.dicvalue" :name="item.dickey">
        </van-tab>
      </van-tabs>
    </div>
    <div class="content">
      <ul>
      <li v-for="(it,ind) in dataSource" :key="ind" @click="jumpUrl(it)">
        <img :src="it.fileUrl" alt="">
        <span>{{ it.exampleName }}</span>
      </li>
      <van-empty v-if="!(dataSource&&dataSource.length)" image-size="100" description="暂无数据" />
    </ul>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import {ref} from 'vue'
import { useRouter } from 'vue-router';
const  router = useRouter()
const activeKey = ref('');
const dataSource = ref([])
const tabList = ref([])

axios.get('/rd_boot_web/rdExampleInfo/getDictionaryToExampleType').then(res=>{
  // console.log(res.data,'左侧字典')
  if(res.data.code == 200){
    tabList.value = res.data.data;
    activeKey.value = res.data.data[0].dickey;
    getList()
  }
})

const getList = ()=>{
  axios.get('/rd_boot_web/rdExampleInfo/queryByExampleType',{params:{exampleType:activeKey.value}}).then(res=>{
    // console.log(res.data.data,'右侧数据')
    if(res.data.code == 200){
      dataSource.value = res.data.data;
    }
  })
}


const jumpUrl = (data)=>{
  router.push({path:'/case/detail',query:{id:data.id}})
}
const tabChange = (key)=>{
  activeKey.value = key;
  getList()
}
</script>
<style lang="less" scoped>
.wrapper{
  position: relative;
  top: -12px;
  .content{
    ul{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px 0;
      img{
        max-width: 325px;height: 200px;
        margin-bottom: 27px;
      }
    }
    li{margin-bottom: 38px;width: 325px;}
    li span{
      display: block;
      font-size: 24px;
      color: #5F6569;
      font-weight: bold;
    }
  }
}
</style>